class Modal {
  constructor(modalContainerDOM) {
    this.modalContainerDOM = document.querySelector(modalContainerDOM);
    this.modalCancelDOM = this.modalContainerDOM.querySelectorAll(
      ".js-modal-cancel"
    );
    this.modalTitleDOM = this.modalContainerDOM.querySelector(".js-title");
    this.modalContentDOM = this.modalContainerDOM.querySelector(
      ".js-modal-content"
    );

    this.callbackOnModalClose;
  }

  toggleModal({ callbackOnModalClose } = {}) {
    this.modalContainerDOM.classList.toggle("is-active");

    if (callbackOnModalClose && typeof callbackOnModalClose === "function") {
      this.callbackOnModalClose = callbackOnModalClose;
    }
  }
}

class ModalPopInfo extends Modal {
  constructor(modalContainerDOM) {
    super(modalContainerDOM);

    this._bindEvents();
  }

  _bindEvents() {
    this.modalCancelDOM.forEach(cancel =>
      cancel.addEventListener("click", () => this.toggleModal())
    );
  }

  open(id, index, content) {
    this.modalContainerDOM.classList.add("is-active");
    // this.modalContentDOM.parentNode.classList.add("is-loading");

    // const callback = (flag, data) => {
    //   this.modalContentDOM.parentNode.classList.remove("is-loading");
    //   this.modalContentDOM.innerHTML = data.body;
    // };
  }
}

export { Modal, ModalPopInfo };
