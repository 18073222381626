/** Togglers in header */
;(() => {
	const headerDOM = document.querySelector('.js-site-header')
	const bodyDOM = document.querySelector('body')
	const burgerDOM = document.querySelector('.js-burger-menu')
	window.menuToggler = function(){
		headerDOM.classList.toggle('is-menu-toggled')
		burgerDOM.classList.toggle('is-active')
		bodyDOM.classList.toggle('overflow-hidden')
	}
})();

;(() => {
	window.changeListItem = function(list, index){
		const listItems = document.querySelectorAll(list)
		listItems.forEach(x => x.classList.remove('is-active'))
		listItems[index].classList.add('is-active')
	}
})();

const verticalShuttersDOM = document.querySelectorAll('.js-vertical-shutter');
if(verticalShuttersDOM){
	verticalShuttersDOM.forEach(x => {
		x.addEventListener('click', e => {
			verticalShuttersDOM.forEach(y => y.classList.remove('is-active'))
			x.classList.add('is-active')
		})
	})
}