function showFormGroup(element) {
	const elementsDOM = document.querySelectorAll(element)


  const inputCheck = function(input, hiddenDOM, flag = true) {
    if (input.checked == true) {
      hiddenDOM.classList.add("is-shown")
    } else {
      flag && hiddenDOM.classList.remove("is-shown")
    }
  }

  elementsDOM.forEach(elementDOM => {
		const hiddenDOM = document.querySelector(`[data-hidden-group="${elementDOM.getAttribute('data-show-group')}"]`)
    // const radioButtons = parent.querySelectorAll("[type=radio]")

    // check after first render
    inputCheck(elementDOM, hiddenDOM, false)

		elementDOM.addEventListener("change", function() {
			inputCheck(this, hiddenDOM)
		})
  })
}

function addElementAfter(targetDOM, element){
	let node;
	if(element.includes('.')){
		node = document.createElement('div')
		node.classList = element.replace('.', '')
	}
	const targetParentDOM = targetDOM.parentNode;
	targetParentDOM.appendChild(node)
}

function manageFileInput(targetDOM, element){
	targetDOM.addEventListener('change', event => {
		let fileName = '';
		if( targetDOM.files && targetDOM.files.length > 1 )
			fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
		else{
			fileName = targetDOM.value.split( '\'' ).pop()
		}

		targetDOM.parentNode.parentNode.parentNode.querySelector('.js-file-text').innerHTML = fileName
	})
}

function isValidPhone(phoneNumber) {
	// var found = phoneNumber.search(/^\d{9}$/g);
	// if(found > -1) {
	// 	return true;
	// }
	// else {
	// 	return false;
	// }
	var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/;
  if(phoneNumber.match(phoneno)) {
    return true;
  }  
  else {  
    return false;
  }
}

export {
	manageFileInput,
	showFormGroup,
	addElementAfter,
	isValidPhone
}