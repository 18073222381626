import { addElementAfter, isValidPhone } from '../modules/forms'


const selectsDOM = document.querySelectorAll('.form-select select')
selectsDOM.forEach(s => addElementAfter(s, '.icon'))

const templatesDOM = document.querySelectorAll('.js-template')
const radiosWrapperDOM = document.querySelector('.js-replace-into-templates')

const renderTemplate = (bgImageSrc, imgSrc, index) => {
	return `
	<div class="p-4 w-full md:w-1/2">
		<div class="flex flex-col items-center cursor-pointer border-2 border-transparent pt-4 px-4 bg-cover js-template group" onclick="selectRadio(${index})" style="background-image:url('${bgImageSrc}')">
			<div class="h-48">
				<img src="${imgSrc}" class="block w-full h-full object-contain" />
			</div>
		</div>
	</div>`
}
try{
		
	if(templatesDOM && radiosWrapperDOM){
		const bundleSelect = document.querySelector('[name="bundle"]');
		const totalPrice = document.querySelector('.js-total-price')
		const priceForBundleDOM = document.querySelectorAll('.js-price-for-bundle')
		totalPrice.innerHTML = parseInt(priceForBundleDOM[bundleSelect.selectedIndex].innerHTML);
		bundleSelect.addEventListener('change', () => {
			totalPrice.innerHTML = parseInt(priceForBundleDOM[bundleSelect.selectedIndex].innerHTML);
		})


		const radiosWrapperContentDOM = radiosWrapperDOM.querySelector('.js-content')
		let templateString = "";
		templatesDOM.forEach((x, index) => {
			templateString += renderTemplate(x.getAttribute('data-bg'), x.querySelector('img').getAttribute('data-src'), index)
		})

		radiosWrapperContentDOM.innerHTML = `<div class="flex flex-wrap -mx-4">${templateString}</div>`

		window.selectRadio = function (index) {
			const templatesInRadioWrapperDOM = radiosWrapperContentDOM.querySelectorAll('.js-template')
			templatesInRadioWrapperDOM.forEach((x, loopIndex) => {
				x.classList.remove('border-primary')
				if(loopIndex == index) x.classList.add('border-primary')
			})
		
			const radioButtonsDOM = radiosWrapperDOM.querySelectorAll('[name="template"]')
			radioButtonsDOM.forEach((x, loopIndex) => {
				x.checked = false;
				if(loopIndex == index){
					x.checked = true;
				}
			})
		}
	}

}catch(e){}


const fileInput = document.querySelector('.wpcf7-file')
if(fileInput){
	fileInput.addEventListener('change', () => {
		const filename = document.querySelector('.form-group__file-name')
		filename.textContent = 'Dodano plik: '+fileInput.files[0].name;
	})
}

const formTitle = document.querySelector('.js-form-title')
if(formTitle){
	const title = document.querySelector('[name="title"]')
	title.value = formTitle.getAttribute('data-title')
}

const cf7PhoneNumber = document.querySelector('.wpcf7-form input[type="tel"]')
console.log(cf7PhoneNumber)
if(cf7PhoneNumber){
	cf7PhoneNumber.addEventListener('keyup', (e) => {
		console.log(isValidPhone(e.target.value))
		const parent = e.target.parentNode;
		const errorLabel = parent.querySelector('.wpcf7-not-valid-tip')
	
		if(!isValidPhone(e.target.value)){
			if(errorLabel) errorLabel.textContent = 'Numer telefonu jest nieprawidłowy';
			if(!errorLabel) {
				const el = document.createElement('span')
				el.classList = 'wpcf7-not-valid-tip'
				el.textContent = 'Numer telefonu jest nieprawidłowy'
				parent.appendChild(el)
			}
		}else{
			if(errorLabel) errorLabel.remove();
		}
	})
}