import "../styles/main.scss";

// import Sticky from "sticky-js";
// import Rellax from "rellax";
import fslighbox from "fslightbox";
import ScrollAction from "./modules/scroll-action";

// import anime from "animejs";
// import "./vendor/paraxify.js";

import "./use-cases/slider";
import "./use-cases/interacts";
import "./use-cases/onscroll";
import "./use-cases/modal";
import "./use-cases/lazyload";
import "./use-cases/forms";
import "./use-cases/accordion";
import "./use-cases/cookies";
import "./use-cases/svg";
import "./use-cases/vue";
import "./use-cases/count-up";


new ScrollAction({
	wrapper: ".js-site-header",
	offset: 0,
	classToAdd: "is-sticky",
	postClassToAdd: "is-post-sticky",
	preClassToAdd: "is-pre-sticky",
	prePostClassesTimeout: 1,
}).init();



// new Rellax(".rellax");
// new Sticky(".js-sticky");
// paraxify(".parallax");
