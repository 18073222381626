import { reveal } from '../modules/scroll-reveal';
// import wp from '../vendor/noframework.waypoints';

/** Hide */
window.fade = function(el){
	if(el){
		document.querySelector(el).classList.add('is-faded')
		document.querySelector('.js-site-header').removeAttribute('style')
	}
	reveal('.js-reveal');

	var urlHash = window.location.href.split("#")[1];
	if(urlHash){
		const el = document.querySelector('#'+urlHash);
		setTimeout(() => {
			if(el) el.scrollIntoView()
		}, 1000)
	}
}


// ;(() => {
// 	var waypoints = document.querySelectorAll('.js-intersection');
// 	if(!waypoints) return;
// 	var waypointItems = document.querySelectorAll('.js-waypoint-item');
// 	if(!waypointItems) return;

// 	const observer = new IntersectionObserver((entries) => {
// 		entries.forEach((entry, index) => {
// 			console.log(entry)
// 			console.log(index, entry.isIntersecting, entry.intersectionRatio, entry.target.id)
// 			const item = document.querySelector(`[href="#${entry.target.id}"`)

// 			if(entry.intersectionRatio > 0){
// 				// waypointItems.forEach(x => {
// 				// 	x.classList.remove('bg-primary');
// 				// 	x.classList.remove('bg-primary');
// 				// 	x.classList.remove('xl:w-40');
// 				// 	x.querySelector('svg').classList.remove('text-white');
// 				// })

// 				item.classList.add('bg-primary');
// 				item.classList.add('bg-primary');
// 				item.classList.add('xl:w-40');
// 				item.querySelector('svg').classList.add('text-white');
// 			}else{
// 				// waypointItems.forEach(x => {
// 				// 	x.classList.remove('bg-primary');
// 				// 	x.classList.remove('bg-primary');
// 				// 	x.classList.remove('xl:w-40');
// 				// 	x.querySelector('svg').classList.remove('text-white');
// 				// })
// 				item.classList.remove('bg-primary');
// 				item.classList.remove('bg-primary');
// 				item.classList.remove('xl:w-40');
// 				item.querySelector('svg').classList.remove('text-white');
// 			}
// 		})
// 	}, {})
// 	waypoints.forEach(el => observer.observe(el))
// })();







// const idleDurationSecs = 0.3;    // X number of seconds
// let idleTimeout; // variable to hold the timeout, do not modify
// const waypointMenu = document.querySelector('.js-waypoint-nav')
// if(waypointMenu){
// 	const resetIdleTimeout = function() {
// 		// Clears the existing timeout
// 		if(idleTimeout) {
// 			clearTimeout(idleTimeout);
// 		}
	
// 		// Set a new idle timeout to load the redirectUrl after idleDurationSecs
// 		idleTimeout = setTimeout(() => {
// 			waypointMenu.classList.add('md:-translate-x-24')
// 		}, idleDurationSecs * 1000);
// 	};
	
// 	// Init on page load
// 	resetIdleTimeout();
	
// 	// Reset the idle timeout on any of the events listed below
// 	['scroll'].forEach(evt =>
// 			document.addEventListener(evt, resetIdleTimeout, false)
// 	);
// 	document.addEventListener('scroll', () => {
// 		waypointMenu.classList.remove('md:-translate-x-24')
// 	})
// }

// /* -------------------------------------------------------------------------- */
// /*                                  WAYPOINTS                                 */
// /* -------------------------------------------------------------------------- */
// var waypoints = document.querySelectorAll('section');
// var waypointItems = document.querySelectorAll('.js-waypoint-item');
// for (var i = waypoints.length - 1; i >= 0; i--) {
//     var waypoint = new Waypoint({
//         element: waypoints[i],
//         handler: function(direction) {
//           this.element.classList.toggle('in-view');
// 					const id = this.element.getAttribute('id');

// 					waypointItems.forEach(x => {
// 						if(x.getAttribute('href') == "#"+id){
// 							x.classList.add('bg-primary');
// 							x.classList.add('xl:w-40');
// 							x.querySelector('svg').classList.add('text-white');
// 						}else{
// 							x.classList.remove('bg-primary');
// 							x.classList.remove('xl:w-40');
// 							x.querySelector('svg').classList.remove('text-white');
// 						}
// 					})
//         },
//         offset: function() {
// 					return 400
// 				}
//     });

		// var waypoint2 = new Waypoint({
		// 	element: waypoints[i],
		// 	handler: function(direction) {
		// 		this.element.classList.toggle('in-view');
		// 		const id = this.element.getAttribute('id');

		// 		waypointItems.forEach(x => {
		// 			if(x.getAttribute('href') == "#"+id){
		// 				x.classList.add('bg-primary');
		// 				x.querySelector('svg').classList.add('text-white');
		// 			}else{
		// 				x.classList.remove('bg-primary');
		// 				x.querySelector('svg').classList.remove('text-white');
		// 			}
		// 		})
		// 	},
		// 	offset: function() {
		// 		return 0
		// 	}
	// });
// }