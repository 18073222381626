import Vue from 'vue/dist/vue.js'
import fetchWrapper from "../modules/fetchWrapper";


new Vue({
	el: "#v-search",
	data(){
		return {
			phrase: "",
			isModalVisible: false,
			isLoading: false,
			items: [],
		}
	},
	methods: {
		async submit(e) {
			e.preventDefault();
			this.isLoading = true;

			const queryString = new URLSearchParams({phrase: this.phrase}).toString();

			const [status, result] = await fetchWrapper({
				url: `/wp-admin/admin-ajax.php?action=search_phrase&${queryString}`,
				method: "GET",
			});

			console.log(status, result)
			this.isLoading = false;

			if(status >= 300){

			}else{
				this.isModalVisible = true;
				this.items = result.data
			}
		},
		highlight(text) {
			return text.replace(new RegExp(this.phrase, "gi"), match => {
				return '<span class="font-bold">' + match + '</span>';
			});
		}
	},
	mounted(){
		document.querySelector('#v-search').removeAttribute('style')
	}
})
