import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";

let possibleSliders = [
	".js-slider-banner", ".js-slider-types", ".js-slider-references", ".js-slider-gallery"
];
(async () => {
	if (possibleSliders.some((x) => document.querySelector(x))) {
		let Swiper = await import("swiper");
		Swiper = Swiper.default
		Swiper.use([Navigation, Pagination, EffectFade, Autoplay])
		const sliderBanner = new Swiper(".js-slider-banner .swiper-container", {
			autoplay: {
				delay: 5000,
			},
			speed: 1000,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			pagination: {
				el: ".js-slider-banner .swiper-pagination",
				type: "bullets",
				clickable: true,
			},
			navigation: {
				prevEl: ".js-slider-banner .js-swiper-nav-prev",
				nextEl: ".js-slider-banner .js-swiper-nav-next",
			},
		});

		const sliderTypes = new Swiper(".js-slider-types .swiper-container", {
			simulateTouch: false,
			speed: 1000,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			navigation: {
				prevEl: ".js-slider-types .js-swiper-nav-prev",
				nextEl: ".js-slider-types .js-swiper-nav-next",
			},
		});

		if(sliderTypes){
			const sliderTypesNavItems = document.querySelectorAll('.slider-types-nav__item')

			window.sliderTypesSlideTo = (index) => {
				if(sliderTypesNavItems){
					sliderTypesNavItems.forEach(x => x.classList.remove('is-active'))
					sliderTypesNavItems[index].classList.add('is-active')
				}
				sliderTypes.slideTo(index)
			}
		}

		const sliderReferences = new Swiper(".js-slider-references .swiper-container", {
			speed: 1000,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			pagination: {
				el: ".js-slider-references .swiper-pagination",
				type: "bullets",
				clickable: true,
			},
			navigation: {
				prevEl: ".js-slider-references .js-swiper-nav-prev",
				nextEl: ".js-slider-references .js-swiper-nav-next",
			},
		});

		const sliderInfos = new Swiper(".js-slider-infos .swiper-container", {
			// simulateTouch: false,
			speed: 1000,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			loop: true,
		});
		if(sliderInfos){
			window.sliderInfosSlideNext = () => {
				const flag = sliderInfos.slideNext()
				if(!flag) sliderInfos.slideTo(0)
			}
		}

		const sliderGallery = new Swiper(".js-slider-gallery .swiper-container", {
			speed: 1000,
			navigation: {
				prevEl: ".js-slider-gallery .js-swiper-nav__next",
				nextEl: ".js-slider-gallery .js-swiper-nav__prev",
			},
		});
	}
})();
