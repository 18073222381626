import { CountUp } from "countup.js";

const elements = document.querySelectorAll('.js-count-up')

elements.forEach(x => {
	const delay = x.dataset.delay || 0

	let countup = new CountUp(x, x.dataset.to || 100, {
		duration: x.dataset.duration || 10,
		separator: x.dataset.separator || ''
	});

	console.log(x.dataset.separator)

	try{
		const observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if(entry.intersectionRatio > 0){
					setTimeout(() => {
						if(!countup.error) countup.start();
					}, delay)
				}
			})
		})
		observer.observe(x)
	}catch(err){
		if(!countup.error) countup.start();
	}
})