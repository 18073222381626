export default class Cookies{
	createCookie({value, name, setAsArray = false, days = 335}){
		if(!value || !name) return false;

		const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
		if(match && !setAsArray) return false;

		const date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		if(!setAsArray){
			document.cookie = `${name}=${value}; expires=${date}; path=/`
		}else{
			let temp = [value]
			if(match){
				temp.push(...JSON.parse(match[2]))
				let temp2 = JSON.parse(match[2]);
				if(temp2.includes(value)) return;
			}

			document.cookie = `${name}=${JSON.stringify(temp)}; expires=${date}; path=/`
		}

		return true;
	}

	getCookie(name){
		const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
		if(match) return match[2];
		return false;
	}
}