import { ModalPopInfo } from "../modules/Modal";

// try {
// 	const popOrder = new ModalPopInfo(".js-modal-order");
	
// 	const btns = document.querySelectorAll("a[href^='#order']")
// 	btns.forEach(x => {
// 		x.addEventListener('click', (e) => {
// 			popOrder.open()
// 			if(x.getAttribute('href').includes('pakiet')){
// 				let splitted = x.getAttribute('href').split('pakiet=');

// 				const selectDOM = document.querySelector('select')
// 				selectDOM.value = splitted[1]
// 			}
// 		})
// 	})
// } catch (err) {console.log(err)}