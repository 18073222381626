// import ScrollReveal from "scrollreveal";

// ScrollReveal.debug = true;
// window.sr = ScrollReveal();

// const reveal = (className) => {
// 	const itemsDOM = document.querySelectorAll(className);

// 	itemsDOM.forEach((item) => {
// 		const dir = item.getAttribute("data-dir");
// 		const container = item.getAttribute("data-container");

// 		console.log(item, item.parentNode)
// 		let slideObj = {
// 			// container: item.parentNode,
// 			distance: item.getAttribute("data-distance") || "50px",
// 			opacity: 0,
// 			easing: "ease-in-out",
// 			duration: item.getAttribute("data-duration") || 600,
// 			viewFactor: 0.1,
// 			delay: item.getAttribute("data-delay") || 0,
// 			mobile: false,
// 			scale: item.getAttribute("data-scale") || 1,
// 			interval: 0,
// 		};

// 		if (dir) slideObj.origin = dir;
// 		else slideObj.origin = "bottom";

// 		if (container) slideObj.container = container;


// 		sr.reveal(item, slideObj);
// 	});
// };

// const flip = (className) => {
// 	const itemsDOM = document.querySelectorAll(className);
// 	itemsDOM.forEach((item) => {
// 		sr.reveal(item, {
// 			distance: "30px",
// 			opacity: 0,
// 			easing: "ease-in-out",
// 			duration: 1000,
// 			rotate: {
// 				x: 90,
// 				z: 45,
// 			},
// 			mobile: false,
// 		});
// 	});
// };



const reveal = (className) => {
	const elements = document.querySelectorAll(className)
	elements.forEach(el => {
		el.style.opacity = "0"
	})
	const observer = new IntersectionObserver((entries) => {
		entries.forEach(entry => {
			let options = {
				easing: "ease-in-out",
				duration: entry.target.dataset.duration || "700",
				delay: entry.target.dataset.delay || "0",
				animation: entry.target.dataset.animation || "reveal-bottom"
			};


			if(entry.intersectionRatio > 0 || entry.isIntersecting){
				entry.target.style.animation = `${options.animation} ${options.duration}ms ${options.delay}ms forwards ease-out`
			}else{
				// entry.target.style.animation = 'none'
			}
		})
	})
	elements.forEach(el => observer.observe(el))
}

export { reveal };
